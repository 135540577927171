export const SET_INIT = "setInit"
export const SET_LOGGEDIN = "setLoggedIn"
export const SET_USERNAME = "setUsername"
export const SET_PREMIUM = "setPremium"
export const SET_INITIALDATA = "setInitialData"
export const SET_PREMIUMTYPE = "setPremiumType"
export const SET_OLDPREMIUM = "setOldPremium"
export const SET_EDUCATION = "setEducation"
export const SET_FOLDERS = "setFolders"
export const SET_GROUPS = "setGroups"
export const SET_AUTOTRANSLATE = "setAutoTranslate"
export const SET_LASTBOOKLANGUAGE = "setLastBookLanguage"
export const SET_LASTBOOKFILTERS = "setLastBookFilters"
export const SET_LASTSEARCHFILTERS = "setLastSearchFilters"
export const SET_SELECTEDINPUTINDEX = "setSelectedInputIndex"
export const SET_EDNEEDSUPDATE = 'setEdNeedsUpdate'
export const SET_STREAK = 'setStreak'