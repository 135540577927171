export const loadingMixin = {
  install(Vue) {
    Vue.mixin({
      methods: {
        /**
         * Intercepts a Promise (action) and sets a local property
         * to true before executing that promise. After promise is fulfilled or rejected,
         * the property is set to false
         *
         * @param {Promise} action promise to set loading before and after promise was fulfilled/rejects
         * @param {string} localProperty instance property name to set loading value
         * @param {string} [defaultValue] default property name to set
         */
        registerLoading(action, localProperty, defaultValue = false) {
          this.status[localProperty] = defaultValue;
          let originalMethod = this[action];
          this[action] = function() {
            this.status[localProperty] = true;
            return originalMethod
              .apply(this, arguments)
              .then(data => {
                this.status[localProperty] = false;
                return Promise.resolve(data);
              })
              .catch(err => {
                this.status[localProperty] = false;
              });
          };
        }
      },
      mounted() {
        let { loading } = this.$options;
        /**
         * We are looking for loading: {} in the component instance
         * If we find it, then we search for `methods` and `property` attributes so we can handle them
         */
        if (loading) {
          if (loading.length) {
            loading.map(object => {
              this.registerLoading(object.method, object.property, object.defaultValue);
            });
          }
        }
      }
    });
  }
};