<template>
  <div class="profile-pic">
    <img :src="getParticipantAvatar(username)" alt="">
  </div>
</template>
<script>
export default {
  props: {
    username: String,
    library: {
      type: String,
      default: 'big-smile'
    }
  },
  methods: {
    getParticipantAvatar(username) {
      return `https://api.dicebear.com/6.x/${this.library}/svg?seed=${username || 'ikleeralles'}`
    },
  }
}
</script>
<style lang="scss" scoped>
.profile-pic {
  width: 60px;
  display: flex;
  align-items: center;
}
</style>
