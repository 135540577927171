<script>
export default {
  methods: {
    reload() {
      location.reload();
    }
  }
}
</script>
<template>
  <div class="container">
    <div class="has-text-centered">
      <p>Er lijkt iets verkeerd te zijn gegaan met het laden van de pagina.</p>
      <br/>
      <b-button type="is-primary" @click="reload()">Herladen</b-button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.loading-icon {
  max-width: 50px;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
</style>