import Vue from 'vue'
import Vuex from 'vuex'

import router from "@/router"
import axios from 'axios'
import { EventBus } from "../../common/event-bus"
import { settings } from 'nprogress'
import { cloneDeep, filter } from 'lodash'
// import { SET_INIT, SET_LISTID } from "./mutation-types"

Vue.prototype._ = _
Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    init: false,
    listId: null,
    name: '',
    original: '',
    translated: '',
    allowCorrectPrevious: true,
    settings: {
      type: 'typing',
      order: 'normal',
      extended: true,
      capitals: true,
      accents: true,
      firstletter: false,
      vowels: false,
      questionAmount: 0,
      timecorrect: 2000,
      timewrong: 2000,
      enterSkip: false,
      subsetLength: 7,
    },
    list: [],
    listCopy: [],
    currentSubset: null,
    askedWord: {
      color: '',
      original: [],
      translated: [],
      translatedImage: ''
    },
    previousWord: {},
    hint: '',
    showHint: false,
    countTogo: 0,
    countMistakes: 0,
    countAsked: 0,
    countCorrect: 0,
    mistakes: [],
    startTime: null,
    randomAnswerOptions: []
  },
  mutations: {
    setInit(state, data) {
      state.init = data
    },
    setListId(state, data) {
      state.listId = data
    },
    setName(state, data) {
      state.name = data
    },
    setType(state, data) {
      state.settings.type = data
    },
    setOrder(state, data) {
      state.settings.order = data
    },
    setAdvancedSettings(state, data) {
      state.original = data.original
      state.translated = data.translated

      const currentSettings = state.settings
      state.settings = { ...currentSettings, ...data.settings }
    },
    updateAmount(state, data) {
      state.list = _.sampleSize(state.list, state.settings.questionAmount)
      state.countTogo = state.settings.questionAmount
    },
    updateOrder(state, data) {
      let list = state.list
      //Swap words if its reversed or mixed method
      list.forEach(subset => {
        subset.forEach(word => {
          if (state.settings.order == 'reversed') {
            [word.original, word.translated] = [word.translated, word.original]
            word.originalLang = state.translated
            word.translatedLang = state.original
          } else if (state.settings.order == 'mixed') {
            if (Math.random() < 0.5) {
              [word.original, word.translated] = [word.translated, word.original]
              word.originalLang = state.translated
              word.translatedLang = state.original
            }
          }
        })
      })
    },
    setTimeCorrect(state, data) {
      state.settings.timecorrect = data
    },
    setTimeWrong(state, data) {
      state.settings.timewrong = data
    },
    setAskedWord(state, data) {
      state.askedWord = data
    },
    setPreviousWord(state, data) {
      state.previousWord = data
    },
    setList(state, data) {
      const list = data

      let id = 0
      list.forEach(word => {
        word.originalLang = state.original
        word.translatedLang = state.translated

        word.id = id
        id += 1
      })
      state.listCopy = cloneDeep(list)

      //Divide list into subsets
      for (let i = 0; i < list.length; i += state.settings.subsetLength) {
        const subset = []
        for (let y = i; y < i + state.settings.subsetLength; y++) {
          if (list[y]) subset.push(list[y]) //If not undefined then push into subset
        }
        state.list.push(subset)
      }

    },
    setRandomCurrentSubset(state) {
      state.currentSubset = Math.floor(Math.random() * state.list.length)
    },
    setHint(state, data) {
      state.hint = data
    },
    setShowHint(state, data) {
      state.showHint = data
    },
    removeFromList(state, data) {
      state.list[state.currentSubset].splice(data, 1)
    },
    removeCurrentSubset(state) {
      state.list.splice(state.currentSubset, 1)
    },
    updateMistakes(state, data) {
      state.countMistakes = data
    },
    addCorrect(state, data) {
      state.countCorrect += 1
    },
    addMistake(state, data) {
      let mistake = { original: data.original, translated: data.translated }
      let foundKey = null
      for (let i = 0; i < state.mistakes.length; i++) {
        if (state.mistakes[i].original === mistake.original) {
          foundKey = i
        }
      }
      if (foundKey !== null) {
        state.mistakes[foundKey].count++
      } else {
        mistake.count = 1
        state.mistakes.push(mistake)
      }
    },
    updateTogo(state, data) {
      state.countTogo = data
    },
    updateAsked(state, data) {
      state.countAsked = data
    },
    reset(state) {
      state.list = []
      state.mistakes = []
      state.countMistakes = 0
      state.countTogo = 0
      state.countAsked = 0
    },
    setStartTime(state, data) {
      state.startTime = data
    },
    setRandomAnswerOptions(state) {
      let randomOptions = []
      // Get answer and push to options
      const answer = state.askedWord.translated[Math.floor(Math.random() * state.askedWord.translated.length)]
      const listCopyWithoutAnswer = state.listCopy.filter(word => word.id !== state.askedWord.id)

      let randomElements = _.sampleSize(listCopyWithoutAnswer, 3)

      //If its mixed, look at the correct answer
      randomOptions = randomElements.map((word) => {
        if (state.askedWord.originalLang == state.original) return word.translated[0]
        if (state.askedWord.originalLang == state.translated) return word.original[0]
      })

      randomOptions.push(answer)
      state.randomAnswerOptions = _.shuffle(randomOptions)
    },
    setAllowCorrectPrevious(state, data) {
      state.allowCorrectPrevious = data
    }
  },
  getters: {
    askedWord: state => {
      const askedWord = state.askedWord
      const original = askedWord.original.filter(variant => variant !== "")
      const randomIndex = Math.floor(Math.random() * original.length)

      return { question: original[randomIndex], ...askedWord }
    },
  },
  actions: {
    async getTest({ commit, state }, payload) {
      let response = await Vue.prototype.$http.get("/list/" + payload, { load: true })
      commit('reset')
      commit('setListId', payload)
      if (response.status !== 401) {
        commit('setName', response.data.name)
        commit('setList', response.data.content)
        commit('setRandomCurrentSubset')
        commit('updateAmount')
        commit('updateOrder')
      }
    },
    async startTest({ commit, dispatch }) {
      commit('setStartTime', new Date())
      return await dispatch('getRandomWord')
    },
    async getRandomWord({ commit, dispatch, state }) {
      if (state.countTogo === 0) return await dispatch('endTest')

      let currentSubset = state.list[state.currentSubset]

      if (currentSubset.length == 0) {
        commit('removeCurrentSubset')
        commit('setRandomCurrentSubset')
      }

      currentSubset = state.list[state.currentSubset]

      if (currentSubset.length >= 2) {
        currentSubset = filter(currentSubset, word => word != state.previousWord)
      }
      let randomWord = currentSubset[Math.floor(Math.random() * currentSubset.length)]

      commit('setAskedWord', randomWord)
      commit('setShowHint', false)
      commit('setRandomAnswerOptions')

      return dispatch('generateHint', randomWord)
    },
    async endTest({ commit, dispatch, state }) {
      const totalPoints = state.countCorrect + state.countMistakes
      let mark = ((state.countCorrect / totalPoints) * 9 + 1).toFixed(2)

      mark = parseInt(mark)

      if (mark < 1) mark = 1

      let endTime = new Date()
      let timeElapsed = (endTime - state.startTime) / 1000
      timeElapsed = Math.round(timeElapsed)

      await Vue.prototype.$http.post("test/" + state.listId, { mistakes: state.mistakes, mark: mark, timeElapsed, asked: state.countAsked })
      return router.push('/results')
    },
    generateHint({ commit, dispatch, state }, randomWord) {
      return new Promise((resolve, reject) => {
        let firstAnswer = randomWord.translated[0]
        let hint = ''

        if (state.settings.vowels) {
          hint = firstAnswer.replace(/[^aeiouy]/gi, "_")
        }

        if (state.settings.firstletter) {
          let first = firstAnswer.charAt(0)
          hint = hint.substr(0, 0) + first + hint.substr(1)
        }

        commit('setHint', hint)

        return resolve()
      })
    },
    async checkWord({ commit, dispatch, state }, payload) {
      commit('updateAsked', state.countAsked + 1)

      //Set word as previous asked
      state.previousWord = state.askedWord

      let answer = state.askedWord.translated

      if (!state.settings.capitals) {
        for (let i = 0; i < answer.length; i++) {
          answer[i] = answer[i].toLowerCase()
        }
        payload = payload.toLowerCase()
      }

      if (!state.settings.accents) {
        for (let i = 0; i < answer.length; i++) {
          answer[i] = answer[i].normalize("NFD").replace(/[\u0300-\u036f]/g, "") //Remove accents
        }

        payload = payload.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      }

      let idInSubset = state.list[state.currentSubset].indexOf(state.askedWord)

      if (answer.indexOf(payload) >= 0 || payload === '!correct') {
        commit('removeFromList', idInSubset)
        commit('updateTogo', state.countTogo - 1)
        commit('setAllowCorrectPrevious', false)
        commit('addCorrect')

        new Audio(require('../../assets/sounds/correct.mp3')).play()

        return { correct: true, answer: answer[0] }
      } else {
        if (!state.settings.extended) {
          commit('removeFromList', idInSubset)
          commit('updateTogo', state.countTogo - 1)
        }
        commit('updateMistakes', state.countMistakes + 1)
        commit('addMistake', state.askedWord)
        commit('setAllowCorrectPrevious', true)

        new Audio(require('../../assets/sounds/wrong.mp3')).play()

        return { correct: false, answer: answer[0] }
      }
    },
    async correctPrevious({ commit, dispatch, state }, hasPremium) {
      if (!hasPremium) return

      let idInSubset = state.list[state.currentSubset].indexOf(state.askedWord)

      if (idInSubset == -1 || state.countMistakes == 0) return //If not found or if no mistakes at all

      commit('removeFromList', idInSubset)
      commit('updateTogo', state.countTogo - 1)
      commit('updateMistakes', state.countMistakes - 1)
      commit('setAllowCorrectPrevious', false)
    },
    async textToSpeech({ commit, dispatch, state }, word) {
      //Check if language undefined, then normal order
      if (!word.lang) word.lang = state.original

      //Play audio
      var audio = new Audio(`${Vue.prototype.$http.getBase()}/speech/${word.lang}/${encodeURIComponent(word.text)}`)
      audio.play()
    }
  }
}
