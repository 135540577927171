import Vue from "vue"
import Vuex from "vuex"

import { LIST_GETME, LIST_CREATE, LIST_DELETE, LIST_UPDATE, LIST_RELOAD } from "./action.type"
import { INIT, SET_LISTS, ADD_LISTS, SET_LOADING, DELETE_LISTS, UPDATE_LIST } from "./mutation.type"
import { WORDLIST_SORTED, WORDLIST_BY_ID } from "./getter.type"
import { orderBy } from "lodash"

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    init: false,
    loading: true,
    lists: []
  },
  mutations: {
    [INIT](state, data) {
      state.init = true
    },
    [SET_LOADING](state, data) {
      state.loading = data
    },
    [SET_LISTS](state, data) {
      state.lists = data
    },
    [ADD_LISTS](state, data) {
      state.lists.push(data)
    },
    [DELETE_LISTS](state, listIds) {
      state.lists = state.lists.filter(list => !listIds.includes(list.id))
    },
    [UPDATE_LIST](state, { listId, listData }) {
      const list = state.lists.find(list => listId == list.id)
      listData.content = JSON.stringify(listData.content)
      Object.assign(list, listData) //Update list with listdata
    }
  },
  getters: {
    [WORDLIST_SORTED]: (state) => {
      return orderBy(state.lists, ["id"], ["desc"])
    },
    [WORDLIST_BY_ID]: (state) => async (id) => {
      return await state.lists.find(list => list.id == id)
    }
  },
  actions: {
    async init({ dispatch, commit }) {
      await dispatch(LIST_GETME)
      commit(INIT)
      commit(SET_LOADING, false)
    },
    async [LIST_RELOAD]({ dispatch }) {
      await dispatch(LIST_GETME)
    },
    async [LIST_GETME]({ commit }) {
      const { data } = await Vue.prototype.$http.get("list/me")
      commit(SET_LISTS, data)
    },
    async [LIST_CREATE]({ commit }, list) {
      const { data } = await Vue.prototype.$http.post("list", list)
      commit(ADD_LISTS, data)

      return data
    },
    async [LIST_DELETE]({ commit }, listIds) {
      await Vue.prototype.$http.post("list/delete", { listIds })
      commit(DELETE_LISTS, listIds)
    },
    async [LIST_UPDATE]({ commit }, { listId, listData }) {
      await Vue.prototype.$http.post(`list/${listId}`, listData)
      commit(UPDATE_LIST, { listId, listData })
    }
  }
}
