<template>
  
</template>
<script>
export default {
  
}
</script>
<style lang="scss">
@import "../assets/style/darkmode.scss";

</style>