import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'


import { DialogProgrammatic as Dialog } from 'buefy'
import { TOGGLE_DARKMODE, SET_DARKMODE } from "./mutation.type"

Vue.use(Vuex)
Vue.use(VueCookies)

Vue.$cookies.config("7d", null, process.env.VUE_APP_COOKIE_DOMAIN)

export default {
  namespaced: true,
  state: {
    isMobile: false,
    languages: [],
    educationLevels: [],
    darkMode: false,
  },
  mutations: {
    setMobile(state, data) {
      state.isMobile = data
    },
    setLanguages(state, data) {
      state.languages = data
      window.localStorage.setItem("languages", JSON.stringify(data))
    },
    setEducationLevels(state, data) {
      state.educationLevels = data
      window.localStorage.setItem("levels", JSON.stringify(data))
    },
    [TOGGLE_DARKMODE](state) {
      state.darkMode = !state.darkMode
      $cookies.set("darkMode", state.darkMode)
    },
    [SET_DARKMODE](state, data) {
      state.darkMode = data
    },
  },
  actions: {
    async init(context) {
      let isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone/i.test(navigator.userAgent)) ? true : false
      context.commit('setMobile', isMobile)
      await context.dispatch("getLanguages")
      await context.dispatch("getEducationlevels")

      window.localStorage.removeItem("bookData")

      context.commit(SET_DARKMODE, $cookies.get("darkMode") == "true")
    },
    async getLanguages(context) {
      let localStorageLanguages
      try {
        localStorageLanguages = JSON.parse(window.localStorage.getItem("languages"))

        // Check if localStorageLanguages is an array of languages
        if (!localStorageLanguages || localStorageLanguages[0].code !== "af") {
          await context.dispatch("fetchLanguages")
        } else {
          context.commit('setLanguages', localStorageLanguages)
        }
      } catch (e) {
        await context.dispatch("fetchLanguages")
      }

    },
    async fetchLanguages(context) {
      let response = await Vue.prototype.$http.get("translate/languages", { load: false })
      return context.commit('setLanguages', response.data)
    },
    async getEducationlevels(context) {
      if (window.localStorage.getItem("levels")) {
        try {
          const educationLevels = JSON.parse(window.localStorage.getItem("levels"))

          return context.commit('setEducationLevels', educationLevels)
        } catch(e) {
          window.localStorage.removeItem("levels")
        }
      }

      let response = await Vue.prototype.$http.get("levels", { load: false })
      context.commit('setEducationLevels', response.data)
    },
  }
}
