import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import cookie from 'vue-cookies'
import { SCAN_UPLOAD } from "./action.type"
import { ToastProgrammatic as Toast } from 'buefy'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    loggedIn: false,
  },
  mutations: {
    // [mutation.SET_LOGGEDIN](state, data) {
    //   state.loggedIn = data
    // },
  },
  actions: {
    async [SCAN_UPLOAD](context, { shortId, files, language = 'nl' }) {
      const url = `${process.env.VUE_APP_API}/scan/upload/${shortId}/chat/${language}`

      let formData = new FormData()
      for (var i = 0; i < files.length; i++) {
        let file = files[i]
        formData.append("files", file)
      }

      try {
        const response = await axios({
          method: "post",
          url: url,
          data: formData,
        })

        return response.data
      } catch (e) {
        if(!e.response.status) return Toast.open({
          message: "Er gaat iets verkeerd met uploaden. Heb je een jpg of png bestand geselecteerd?",
          type: "is-danger",
          duration: 5000,
        })

        let message = "Er ging iets verkeerd met het uploaden van de foto"
        if (e.response.status === 415) message = "De foto is geen jpg of png bestand"
        if (e.response.status === 413) message = "De foto is meer dan 10MB"
        if (e.response.status === 503) message = "De server is niet meer beschikbaar"
        if (e.response.status === 429) message = "Je probeert te snel foto's te uploaden, je kunt dit elke 20 seconden doen"
        if (e.response.status === 404) message = "Deze scan bestaat niet meer"
        if (e.response.data.message === "too_little_context") message = "Er is te weinig tekst gedetecteerd in de foto om een overhoring te maken"
        if (e.response.data.message === "no_text_detected") message = "Er is geen tekst gedetecteerd in de foto"
        if (e.response.data.message === "link_already_used") message = "Het lijkt erop dat deze link al is gebruikt"

        Toast.open({
          message,
          type: "is-danger",
          duration: 5000,
        })

        throw new Error("Upload failed ", e.response)
      }
    }
  }
}
