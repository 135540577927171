import Vue from 'vue'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import VueCookies from 'vue-cookies'
import {
		ToastProgrammatic as Toast,
		LoadingProgrammatic as loading
} from 'buefy'
import { config, set, event } from 'vue-gtag'


export default class {
		constructor() {
				//set some defaults
				axios.defaults.baseURL = process.env.VUE_APP_API

				//when the request fails, try again 'retry' times, with a delay of 'retryDelay' ms
				axios.interceptors.response.use(undefined, (err) => {
						const { config, message } = err
						if (!config || !config.retry) {
								return Promise.reject(err)
						}
						config.retry -= 1
						const delayRetryRequest = new Promise((resolve) => {
								setTimeout(() => {
										console.log("retry the request", config.url)
										resolve()
								}, config.retryDelay || 2000)
						})
						return delayRetryRequest.then(() => axios(config))
				})

				config({ id: "UA-47699187-8" })
		}

		async get(url, { load = false, credentials = true, retry = 0 } = { load: false, credentials: true, retry: 0 }) {
				let loadingcomponent
				if (load) loadingcomponent = loading.open()
				try {
						let response
						response = await axios.get(`${ url }`, { withCredentials: credentials, retry })

						if (load) loadingcomponent.close()

						return response
				} catch (error) {
						if (load) loadingcomponent.close()

						event("sse", { event_category: "error", event_label: error, value: 1 })

						if (error.message === 'Network Error') {
								return Toast.open({
										type: "is-danger",
										message: "De verbinding met de server is weggevallen",
										duration: 5000,
										position: "is-bottom-right"
								})
						}

						if (error.response.status === 401 || error.response.status === 404 || error.response.status === 400) {
								return error.response
						} else {
								throw error
						}
				}
		}

		async post(url, params, { load = true, credentials = true } = { load: true, credentials: true }) {
				let loadingcomponent
				let response
				if (load) loadingcomponent = loading.open()

				try {
						response = await axios.post(`${ url }`, params, { withCredentials: credentials })

						if (load) loadingcomponent.close()

						return response
				} catch (error) {
						if (load) loadingcomponent.close()
						event("sse", { event_category: "error", event_label: error, value: 1 })

						if (error.message === 'Network Error') {
								return Toast.open({
										type: "is-danger",
										message: "De verbinding met de server is weggevallen",
										duration: 5000,
										position: "is-bottom-right"
								})
						}

						if (error.response.status === 401 || error.response.status === 404 || error.response.status === 400) {
								return error.response
						} else {
								throw error
						}
				}
		}

		async delete(url, params, { load = true, credentials = true } = { load: true, credentials: true }) {
				let loadingcomponent
				let response
				if (load) loadingcomponent = loading.open()

				try {
						response = await axios.delete(`${ url }`, { withCredentials: credentials })

						if (load) loadingcomponent.close()

						return response
				} catch (error) {
						if (load) loadingcomponent.close()
						event("sse", { event_category: "error", event_label: error, value: 1 })

						throw error
				}
		}

		async streamResponse(url, params) {
				let response = await fetch(`${ process.env.VUE_APP_API + url }`, {
						method: 'POST',
						headers: {
								'Content-Type': 'application/json',
						},
						body: JSON.stringify(params),
				})

				return response.body
		}

		getBase() {
				return axios.defaults.baseURL
		}
}
