<template>
  <div class="modal-card" style="max-width: 500px; margin: 0 auto">
    <header class="modal-card-head has-text-centered">
      <div class="badge">
        <figure class="image is-128x128">
          <img :src="badgeImage" />
        </figure>
      </div>
      <p class="grayed-out">Medaille ontvangen</p>
      <p class="modal-card-title">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <div class="columns is-mobile">
        <div class="column has-text-centered">
          <img src="@/assets/icons/badges/gray.svg" />
          <p><b-icon icon="check" /></p>
        </div>
        <div class="column has-text-centered" :class="{ 'grayed-out': achievement.level < 1 }">
          <img src="@/assets/icons/badges/green.svg" />
          <p v-if="achievement.level < 1" class="grayed-out">10</p>
          <!--TODO: dynamic values from json file-->
          <p v-else><b-icon icon="check" /></p>
        </div>
        <div class="column has-text-centered" :class="{ 'grayed-out': achievement.level < 2 }">
          <img src="@/assets/icons/badges/blue.svg" />
          <p v-if="achievement.level < 2" class="grayed-out">100</p>
          <p v-else><b-icon icon="check" /></p>
        </div>
        <div class="column has-text-centered grayed-out" :class="{ 'grayed-out': achievement.level < 3 }">
          <img src="@/assets/icons/badges/purple.svg" />
          <p v-if="achievement.level < 3" class="grayed-out">500</p>
          <p v-else><b-icon icon="check" /></p>
        </div>
        <div class="column has-text-centered grayed-out" :class="{ 'grayed-out': achievement.level < 4 }">
          <img src="@/assets/icons/badges/gold.svg" />
          <p v-if="achievement.level < 4" class="grayed-out">1.000</p>
          <p v-else><b-icon icon="check" /></p>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button @click="close()" expanded>Sluiten</b-button>
    </footer>
  </div>
</template>

<script>
import achievementTypes from "../../common/achievementTypes.json"

export default {
  props: ["achievement"],
  computed: {
    title() {
      const level = this.$props.achievement.level
      const type = this.$props.achievement.type

      return this.achievementTypes[type].titles[level]
    },
    badgeImage() {
      let image = ""
      switch (this.$props.achievement.level) {
        case 0:
          image = "gray"
          break
        case 1:
          image = "green"
          break
        case 2:
          image = "blue"
          break
        case 3:
          image = "purple"
          break
        case 4:
          image = "gold"
          break
        default:
          break
      }

      return require(`../../assets/icons/badges/${image}.svg`)
    },
  },
  data() {
    return {
      achievementTypes,
    }
  },
  mounted() {
    setTimeout(() => {
      new Audio(require("../../assets/sounds/achievement.mp3")).play()
    }, 200)

    this.$confetti.start({
      defaultDropRate: 10,
      particlesPerFrame: 0.2,
    })

    setTimeout(() => {
      this.$confetti.stop()
    }, 7000)
  },
  methods: {
    close() {
      this.$parent.close()
      this.$confetti.stop()
    },
  },
}
</script>

<style lang="scss" scoped>
.achievement-title {
  font-size: 25px;
  margin-top: 20px;
  font-weight: 600;
}
.modal-card {
  padding-top: 50px;
  .modal-card-head {
    padding-top: 100px;
    font-weight: 600;
    flex-direction: column;
    .badge {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .modal-card-body {
    @media only screen and (max-width: 500px) {
      p {
        font-size: 0.8rem !important;
      }
    }
  }
}

.zoom-in-enter-active,
.zoom-in-leave-active {
  transition: opacity 0.5s;
  .animation-content,
  .animation-content {
    transition: transform 0.5s;
  }
}
.zoom-in-enter,
.zoom-in-leave-active {
  opacity: 0;
  .animation-content,
  .animation-content {
    transform: scale(0.2);
  }
}
</style>
