import Vue from 'vue'
import Vuex from 'vuex'
import { CHAT_INIT, CHAT_GET_OPENAI_STATUS, CHAT_GET_SHORTID } from "./action.type"
import { CHAT_BOTSTATUS, CHAT_GET_PERSONA_IMAGE, CHAT_GET_PERSONA } from './getter.type'
import * as mutation from './mutation.type'

Vue.use(Vuex)

const personaImages = {
  friendly: require('@/assets/studybuddy/friendly-persona.svg'),
  strict: require('@/assets/studybuddy/strict-persona.svg'),
  gangster: require('@/assets/studybuddy/gangster-persona.svg'),
  brabant: require('@/assets/studybuddy/brabant-persona.svg'),
  neutral: require('@/assets/studybuddy/neutral-persona.svg') // default persona
};

export default {
  namespaced: true,
  state: {
    openAIStatus: "offline",
    shortId: null,
    chat: {}
  },
  mutations: {
    [mutation.SET_OPENAISTATUS](state, data) {
      state.openAIStatus = data
    },
    [mutation.SET_SHORTID](state, data) {
      state.shortId = data
    },
    [mutation.SET_PERSONA](state, data) {
      state.persona = data
    },
    [mutation.SET_CHAT_DATA](state, data) {
      state.chat = data
    }
  },
  getters: {
    [CHAT_BOTSTATUS]: (state) => {
      return state.openAIStatus == "online" && state.shortId ? "online" : "storing"
    },
    [CHAT_GET_PERSONA]: (state) => {
      return (state.chat && state.chat.persona) ? state.chat.persona : "default"
    },
    [CHAT_GET_PERSONA_IMAGE]: (getters) => {
      return personaImages[getters['chatGetPersona']]
    }
  },
  actions: {
    async [CHAT_INIT](context, { loggedIn }) {
      await context.dispatch(CHAT_GET_SHORTID, { loggedIn })
    },
    async [CHAT_GET_SHORTID](context, { loggedIn }) {
      //TODO: Token still sent
      const response = loggedIn ? await Vue.prototype.$http.get("scan/init", { retry: 3 }) : await Vue.prototype.$http.get("scan/init/anonymous", { load: false, credentials: false, retry: 3 })

      context.commit(mutation.SET_SHORTID, response.data)
    },
    async [CHAT_GET_OPENAI_STATUS](context) {
      let response = await fetch("https://status.openai.com/api/v2/status.json", {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      })
      response = await response.json()

      if (response.status.indicator !== "none") {
        context.commit(mutation.SET_OPENAISTATUS, "storing")
      } else {
        context.commit(mutation.SET_OPENAISTATUS, "online")
      }
    }
  }
}
