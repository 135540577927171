export function lazyLoadView(AsyncView) {
  let AsyncHandler = () => ({
    component: AsyncView,
    loading: require('../pages/Loading.vue').default,
    delay: 400,
    error: require('../pages/Timeout.vue').default,
    timeout: 5000
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    }
  })
}
