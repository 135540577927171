import Vue from 'vue'
import Vuex from 'vuex'

import router from "@/router"
import axios from 'axios'
import { EventBus } from "../../common/event-bus"
const findIndex = require('lodash/findIndex')

// import { SET_INIT, SET_LISTID } from "./mutation-types"

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    init: false,
    planningId: null,
    planningItemId: null,
    name: '',
    original: '',
    translated: '',
    type: 'overhoren',
    order: 'normal',
    questionAmount: 0,
    timecorrect: 2000,
    timewrong: 2000,
    enterSkip: true,
    list: [],
    askedWord: {
      color: '',
      original: [],
      translated: [],
      lastAsk: null,
      nextAsk: null,
    },
    countTogo: 0,
    countMistakes: 0,
    countAsked: 0,
    mistakes: [],
    startTime: null
  },
  mutations: {
    setInit(state, data) {
      state.init = data
    },
    setPlanningId(state, data) {
      state.planningId = data
    },
    setPlanningItemId(state, data) {
      state.planningItemId = data
    },
    setName(state, data) {
      state.name = data
    },
    setOriginal(state, data) {
      state.original = data
    },
    setTranslated(state, data) {
      state.translated = data
    },
    setTimeCorrect(state, data) {
      state.timecorrect = data
    },
    setTimeWrong(state, data) {
      state.timewrong = data
    },
    setAskedWord(state, data) {
      state.askedWord = data
    },
    setPreviousWord(state, data) {
      state.previousWord = data
    },
    setList(state, data) {
      state.init = true
      state.list = data
    },
    setHint(state, data) {
      state.hint = data
    },
    setNewNextAsk(state, { id, nextAsk, iteration }) {
      state.list[id].nextAsk = nextAsk
      state.list[id].iteration = iteration
    },
    removeFromList(state, data) {
      state.list.splice(data, 1)
    },
    updateMistakes(state, data) {
      state.countMistakes = data
    },
    addMistake(state, data) {
      let mistake = { original: data.original, translated: data.translated }
      let foundKey = null
      for (let i = 0; i < state.mistakes.length; i++) {
        if (state.mistakes[i].original === mistake.original) {
          foundKey = i
        }
      }
      if (foundKey !== null) {
        state.mistakes[foundKey].count++
      } else {
        mistake.count = 1
        state.mistakes.push(mistake)
      }
    },
    updateTogo(state, data) {
      state.countTogo = data
    },
    updateAsked(state, data) {
      state.countAsked = data
    },
    reset(state) {
      state.list = []
      state.mistakes = []
      state.countMistakes = 0
      state.countTogo = 0
      state.countAsked = 0
    },
    setStartTime(state, data) {
      state.startTime = data
    }
  },
  getters: {
    askedWord: state => {
      const { original, originalLang, translatedLang, color, translated } = state.askedWord
      const randomIndex = Math.floor(Math.random() * original.length)

      return { question: original[randomIndex], originalLang, translatedLang, color, translated }
    },
  },
  actions: {
    updateOrder({ commit, dispatch, state }, data) {
      let list = state.list
      for (let i = 0; i < list.length; i++) {
        list[i].originalLang = state.original
        list[i].translatedLang = state.translated
      }
      state.list = list
    },
    async getTest(context, payload) {
      let response = await Vue.prototype.$http.get("/planning/planningitem/" + payload)
      context.commit('reset')
      if (response.status !== 401) {
        context.commit('setList', response.data.content)
        context.commit('setOriginal', response.data.planning.list.original)
        context.commit('setTranslated', response.data.planning.list.translated)

        context.commit('updateTogo', response.data.content.length)
        context.commit('setPlanningId', response.data.planning.id)
        context.commit('setPlanningItemId', payload)

        context.dispatch('updateOrder')
        context.dispatch('getRandomWord')
        context.commit('setStartTime', new Date())
      }
    },
    async getRandomWord({ commit, dispatch, state }) {
      if (state.countTogo === 0) return dispatch('endTest')

      commit('updateAsked', state.countAsked + 1)

      let randomWord = state.list[Math.floor(Math.random() * state.list.length)]

      commit('setAskedWord', randomWord)

      return dispatch('generateHint', randomWord)
    },
    async endTest({ commit, dispatch, state }) {
      let points = state.countAsked - state.countMistakes
      let mark = ((points / state.countAsked) * 9 + 1).toFixed(2)
      
      if(mark < 1) mark = 1

      let endTime = new Date()
      let timeElapsed = (endTime - state.startTime) / 1000
      timeElapsed = Math.round(timeElapsed)

      await Vue.prototype.$http.post("planning/finish/" + state.planningItemId, { mistakes: state.mistakes, mark: mark, timeElapsed })
      return router.push('/planning/' + state.planningId)
    },
    generateHint({ commit, dispatch, state }, randomWord) {
      return new Promise((resolve, reject) => {
        let firstAnswer = randomWord.translated[0]
        let hint = ''

        if (state.vowels) {
          hint = firstAnswer.replace(/[^aeiouy]/ig, "_")
        }

        if (state.firstletter) {
          let first = firstAnswer.charAt(0)
          hint = hint.substr(0, 0) + first + hint.substr(1)
        }

        commit('setHint', hint)

        return resolve()
      })
    },
    async checkWord({ commit, dispatch, state }, payload) {
      //Set word as previous asked
      state.previousWord = state.askedWord

      let answer = state.askedWord.translated

      if (!state.capitals) {
        for (let i = 0; i < answer.length; i++) {
          answer[i] = answer[i].toLowerCase()
        }
        payload = payload.toLowerCase()
      }

      if (!state.accents) {
        for (let i = 0; i < answer.length; i++) {
          answer[i] = answer[i].normalize("NFD").replace(/[\u0300-\u036f]/g, "") //Remove accents
        }
        payload = payload.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      }

      let idInList = state.list.indexOf(state.askedWord)

      if (answer.indexOf(payload) >= 0 || payload === '!correct') {
        commit('removeFromList', idInList)
        commit('updateTogo', state.countTogo - 1)

        new Audio(require('../../assets/sounds/correct.mp3')).play()

        return { correct: true }
      } else {
        if (!state.extended) {
          commit('removeFromList', idInList)
          commit('updateTogo', state.countTogo - 1)
        }
        commit('updateMistakes', state.countMistakes + 1)
        commit('addMistake', state.askedWord)

        new Audio(require('../../assets/sounds/wrong.mp3')).play()

        return { correct: false, answer: answer[0] }
      }
    },
    async correctPrevious({ commit, dispatch, state }, hasPremium) {
      if (!hasPremium) {
        return
      }
      let list = state.list
      let idInList = list.indexOf(state.previousWord)
      if (idInList == -1) {
        return
      }
      commit('removeFromList', idInList)
      commit('updateTogo', state.countTogo - 1)
      commit('updateMistakes', state.countMistakes - 1)
      return await dispatch('getRandomWord')
    },
    async textToSpeech({ commit, dispatch, state }, word) {
      //Check if language undefined, then normal order
      if (!word.lang) {
        word.lang = state.original
      }

      //Play audio
      var audio = new Audio(Vue.prototype.$http.getBase() + '/speech/' + word.lang + '/' + word.text)
      audio.play()
    }
  }
}
