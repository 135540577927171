import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=5a9c1c44&scoped=true&"
var script = {}
import style0 from "./Loading.vue?vue&type=style&index=0&id=5a9c1c44&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a9c1c44",
  null
  
)

export default component.exports