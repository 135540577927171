import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import Buefy from 'buefy'
import VueSocketIO from 'vue-socket.io'
import VueCookies from 'vue-cookies'
import './vee-validate'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Clipboard from 'v-clipboard'

import VueGtag from "vue-gtag"
import VueConfetti from 'vue-confetti'
import CountryFlag from 'vue-country-flag'
import VueWaypoint from 'vue-waypoint'
import posthog from "posthog-js"

//Install lodash and add to vue prototype
import lodash from 'lodash'
Vue.prototype._ = _

import ApiService from './common/api.service'
import './common/filters'
import { loadingMixin } from "./common/mixins"
import AchievementReceived from './components/Achievement/AchievementReceived.vue'
import './common/directives'

Vue.config.productionTip = process.env.NODE_ENV === 'production'

Vue.use(VueConfetti)
Vue.use(Buefy)
Vue.use(VueCookies)
Vue.use(VueGtag, { config: { id: "G-668H46X1F2" } }, router)
Vue.use(Clipboard)
Vue.component('country-flag', CountryFlag)
Vue.use(VueWaypoint)
Vue.use(loadingMixin)
Vue.use(
  new VueSocketIO({
    debug: false,
    connection: process.env.VUE_APP_API,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    },
    withCredentials: false,
    secure: true,
  })
)

posthog.init('phc_QsRubaTtRYlruxKtRbiBdZYLqAf5Y71jIudzUPxUhPW', {
  api_host: 'https://eu.posthog.com', session_recording: {
    maskAllInputs: false
  },
  opt_in_site_apps: true
})
Object.defineProperty(Vue.prototype, '$posthog', { value: posthog })

Vue.$cookies.config("7d", null, process.env.VUE_APP_COOKIE_DOMAIN)

Vue.prototype.$http = new ApiService()

const token = Vue.$cookies.get('token')

const connectToSocket = () => {
  const eventSource = new EventSource(`${process.env.VUE_APP_API}/sse/${token}`)
  eventSource.onmessage = ({ data }) => {
    const parsed = JSON.parse(data)
    if (parsed.type == 'achievement') {
      Vue.prototype.$buefy.modal.open({
        parent: this,
        component: AchievementReceived,
        trapFocus: true,
        animation: "zoom-in",
        props: {
          achievement: parsed.data
        }
      })
    }
  }
  eventSource.onerror = (err) => {
    console.log('error', err)
    setTimeout(() => {
      connectToSocket()
    }, 2000)
  }
}


new Vue({
  router: router,
  store: store,
  render: (h) => h(App),
  mounted() {

    AOS.init({
      duration: 600,
      once: true,
    })
  }
}).$mount('#app')
