import Vue from 'vue'
import Vuex from 'vuex'

import app from './App'
import user from './User'
import test from './Test'
import list from './List'
import scan from './Scan'
import chat from './Chat'
import testPlanningItem from './Test/test-planning-item'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        user,
        test,
        list,
        scan,
        chat,
        testPlanningItem
    }
})
